import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Layout, Parallax, DelayedLink } from '@common'
import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
	page__grid,
	page__grid__row,
	page__faq,
	page__faq__item,
	page__faq__item__active,
} from './styles/page.module.scss'

const faqs = [
	{ title: 'Czy te zestawy są dla mnie?', description: 'Zestawy są dla każdego, kto zaczął swoją przygodę z programowaniem' },
	{ title: 'Za co płacę?', description: 'W zamian za wsparcie mojej inicjatywy otrzymujesz dożywotni dostęp do wybranych zbiorów zadań' },
	{
		title: 'Czy mogę zwrócić się do Ciebie z prośbą o pomoc w trakcie robienia zadań?',
		description: 'TAK - pisz śmiało na fanpage, możesz też zdzwonić się ze mną online w celu zrobienia code review Twoich rozwiązań',
	},
	{
		title: 'Co jeśli potrzebuje przećwiczyć jakiś konkretny temat?',
		description: 'Pisz śmiało na fanpage, powiedz czego potrzebujesz, a ja przygotuję dla Ciebie zadanka do ćwiczeń',
	},
	{ title: 'Jak często wychodzą aktualizacje?', description: 'Regulanie wypuszczam większe updaty, a codziennie poświęcam kilka godzin na szukanie w internecie fajnych pomysłów na zadania' },
	{ title: 'Czy do zadań są rozwiązania?', 
		description: 'Każde rozwiazanie zadania jest dobre, jeśli spełnia warunki z zadania :)' },

	{
		title: 'Na zostaną przeznaczone moje pieniądze?',
		description: 'Na utrzymanie tej strony oraz na to, abym mógł rozwijać dalej zbiory zadań i szerzyć ideę tego typu nauki',
	},
	{
		title: 'W jakiej formie są zadania i projekty?',
		description: 'Formę można sprawdzić na darmowym repozytorium. Staram się opisywać każde zadania tak, aby wszystko było zrozumiałe',
	},
	{ title: 'Mam więcej pytań!', description: 'Pisz śmiało na fanpage, zwykle odpowiadam w przeciągu kilku minut' },
]

const FaqItem = ({ onClick, activeIdx, idx, title, description }) => {
	return (
		<li
			className={`${page__faq__item} ${idx == activeIdx
				? page__faq__item__active
				: ''}`}
		>
			<h2>
				<a
					onClick={() => {
						onClick(idx)
					}}
				>
					{title}
				</a>
			</h2>
			<h5>{description}</h5>
		</li>
	)
}

const Faq = ({ pageContext, location }) => {
	const [idx, setIdx] = useState(0)
	return (
		<Layout
			location={location}
			page={pageContext.page}

		>
			<div className={page__wrapper}>
				<h1>FAQ</h1>
				<h2>Jeśli nie znajdziesz tu odpowiedzi, <strong>odezwij się na fanpage</strong></h2>
				<div className={page__buttons}>
					<div className={`${page__button} ${page__button__alt}`}>
						<a>
							Do FANPAGE !!!
						</a>
						<span>&gt;</span>
					</div>
				</div>
				<br/>
				<br/>
				<ul className={page__faq}>
					{faqs.map((faqItem, key)=> <FaqItem
						{...faqItem}
						idx={key}
						activeIdx={idx}
						onClick={setIdx}
					/>)}
				</ul>

				<div className={page__buttons}>
				<div className={`${page__button}`}>
					<span>&gt;</span>
					<DelayedLink to={'/pricing/'}>Już wszystko wiem</DelayedLink>
				</div>
			</div>
			</div>
		</Layout>
	)
}
export default Faq
